import { React, useState } from "react";
import {
    Box,
    Button,
    Container,
    Center,
    Input 
  } from '@chakra-ui/react'

import updatePass from "../utils/updatePass";
import { useMoralis } from 'react-moralis';

const ResetPass = () => {

  const { user, logout } = useMoralis();
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  
  async function resetPass ()  {
    if ( pass === pass2 ) {
        console.log('UserId ', user.id);
        await updatePass(user.id, pass2); 
        setPass("");
        setPass2("");
        logout();
    } else {
        alert('Password mismatch');
    }
    
  }
  
  return (
    
    <Container>
        <Box>
            <Input 
                type="password"
                placeholder="New Password" 
                value={pass} 
                onChange={(event) => setPass(event.currentTarget.value)} 
            />
            <Input 
                type="password"
                placeholder="Repeat Password" 
                value={pass2} 
                onChange={(event) => setPass2(event.currentTarget.value)} 
            />
            
        </Box>
        <Box mt={2}>
          <Center>
            <Button width='40%'  onClick={() => resetPass()}> Reset Password</Button>    
          </Center>
                        
        </Box>
            
    </Container>

  );
    
};

export default ResetPass;