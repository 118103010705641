import { React, useState } from "react";
import {
    Box,
    Button,
    Container,
    Center,
    Input 
  } from '@chakra-ui/react'


import updateCode from "../utils/updateCode";

const UploadCode = () => {

  const [code, setCode] = useState("");
  const [tokenId, setTokenId] = useState("");
  
  async function uploadCode ()  {
    await updateCode(tokenId, code); 
    setCode("");
    setTokenId("");
  }
  
  return (
    
    <Container>
        <Box>
            <Input 
                placeholder="TokenId" 
                type="number"
                value={tokenId} 
                onChange={(event) => setTokenId(event.currentTarget.value)} 
            />
            <Input 
                placeholder="Code" 
                value={code} 
                onChange={(event) => setCode(event.currentTarget.value)} 
            />
            
        </Box>
        <Box mt={2}>
          <Center>
            <Button width='40%'  onClick={() => uploadCode()}> Upload Code</Button>    
          </Center>
                        
        </Box>
            
    </Container>

  );
    
};

export default UploadCode;