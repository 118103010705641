import Moralis from 'moralis';

export async function updateCode (tokenId, code) {
    
    const Rewards = Moralis.Object.extend("Rewards");
    const query = new Moralis.Query(Rewards);
    query.equalTo("tokenId", parseInt(tokenId));
    const results = await query.find();
    const token = results[0];
    
    token.set('code', code);

    token.save()
    .then((token) => {
        alert('Update Succesful');
        
    
    }, (error) => {
        alert('Failed to update Reward, with error code: ' + error.message);

    });
    
    return;
}

export default updateCode;