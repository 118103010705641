import Moralis from 'moralis';

export async function updatePass (userId, newPass) {
    

    /**
     const User = Moralis.Object.extend("User");
    const query = new Moralis.Query(User);
    query.equalTo("objectId", userId);
    const results = await query.find();
    const user = results[0];

    //const role = 'NewUser';
    user.set('password', newPass);

    user.save()
    .then((user) => {
        alert('Update Succesful');
        
    
    }, (error) => {
        alert('Failed to update password, with error code: ' + error.message);

    });
     * 
     */
    const params =  { 
        userId: 'K4bkCL8hg5DuGYAzRbOTIuGO',
        pass: newPass
    };
    const resetPassword = await Moralis.Cloud.run("resetPass", params);
    console.log('Response: ', resetPassword);
    
    return;
}

export default updatePass;