import { useMoralis } from "react-moralis";
import { React, useState } from "react";
import {
  Box,
  Button,
  Container,
  Center,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import UploadCode from "./UploadCode";
import MintToken from "./MintToken";

const Admin = () => {
  const { logout } = useMoralis();
  const [state, setState] = useState("UploadCode");

  if (state === "UploadCode") {
    return (
      <Container>
        <Menu>
          <MenuButton ml={4} as={Button}>
            Admin
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setState("UploadCode")}>
              Upload Code
            </MenuItem>
            <MenuItem onClick={() => setState("MintToken")}>
              Mint Token
            </MenuItem>
            <MenuItem onClick={() => logout()}>Logout</MenuItem>
          </MenuList>
        </Menu>
        <UploadCode />
      </Container>
    );
  } else if (state === "MintToken") {
    return (
      <Container>
        <Menu>
          <MenuButton ml={4} as={Button}>
            Admin
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setState("UploadCode")}>
              Upload Code
            </MenuItem>
            <MenuItem onClick={() => setState("MintToken")}>
              Mint Token
            </MenuItem>
            <MenuItem onClick={() => logout()}>Logout</MenuItem>
          </MenuList>
        </Menu>
        <MintToken />
      </Container>
    );
  } else {
    return (
      <Container>
        <Menu>
          <MenuButton ml={4} as={Button}>
            Admin
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setState("UploadCode")}>
              Upload Code
            </MenuItem>
            <MenuItem onClick={() => setState("MintToken")}>
              Mint Token
            </MenuItem>
            <MenuItem onClick={() => logout()}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </Container>
    );
  }
};

export default Admin;
