import { useMoralis} from 'react-moralis';
import { useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  Container,
  Center,
  Input
} from '@chakra-ui/react'

const Auth = () => {
    
    const { authenticate, isAuthenticating, authError, login } = useMoralis();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    return(
        <Container>
            {authError && (
                <Alert status="error">
                <AlertIcon />
                <Box flex="1">
                    <AlertTitle>Authentication has failed!</AlertTitle>
                    <AlertDescription display="block">{authError.message}</AlertDescription>
                </Box>
                <CloseButton position="absolute" right="8px" top="8px" onClick={() => window.location.reload(false)}/>
                </Alert>
                )}
            <Box>
                <Input 
                    placeholder="Email" 
                    value={email} 
                    onChange={(event) => setEmail(event.currentTarget.value)} 
                />
                <Input 
                    placeholder="Password" 
                    type="password" 
                    value={password} 
                    onChange={(event) => setPassword(event.currentTarget.value)} 
                />
                
            </Box>
            <Box mt={2}>
                <Center>
                <Button mr={4} width='40%'  onClick={() => login(email, password)}> Login</Button>
                <Button width='40%'  onClick={() => authenticate()}> Metamask </Button>    
                </Center>
                          
            </Box>
            
      </Container>
    );
    
};

export default Auth;