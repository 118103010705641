import { useMoralis } from "react-moralis";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { React, useState } from "react";
import {
    Box,
    Button,
    Container,
    Center,
    Text,
    Tag,
    Link
    
  } from '@chakra-ui/react'

import { HiOutlineExternalLink } from 'react-icons/hi'

import getCode from "../utils/getCode";

const GetRewardCode = () => {

  const { logout, user } = useMoralis();
  const [text, setText] = useState("");
  const [displayed, setDisplayed] = useState(false);
  const [copied, setCopied] = useState(false);
  
  async function showCode ()  {
    let code = await getCode(user.id);
    setText(code);
    setDisplayed('displayed');  
  }

  async function copyCode ()  {
    let code = await getCode(user.id);
    setText(code);
    setDisplayed('displayed');  
  
  }


  return (
    <Container>
      <Box
        borderRadius="lg"
        borderColor="whiteAlpha.200"
        borderWidth="2px"
        height="80px"
      >
        <Center >
          <Text fontSize="50px" color="whiteAlpha.900">
            {text}
          </Text>
        </Center>
      </Box>
      <Box mt={2}>
        <Center>
          <Button mr={4} width="40%" onClick={() => logout()}>
            {" "}
            Logout{" "}
          </Button>
          {displayed ? (
            <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
              <Button width="40%" onClick={() => showCode()}>
                {" "}
                Copy Code
              </Button>
            </CopyToClipboard>
          ) : (
            <Button width="40%" onClick={() => showCode()}>
              {" "}
              Get Reward
            </Button>
          )}
          
        </Center>
        <Center mt={3}>
         
            <Link mr={2} fontSize={20} href="https://sensitivecbd.com" isExternal>
              Redeem your code here 
              
            </Link>
            <Link href="https://sensitivecbd.com" isExternal>
            <HiOutlineExternalLink  mx="2p" />
            </Link>
            
          
          
        </Center>
      </Box>
    </Container>
  );
    
};

export default GetRewardCode;