import Moralis from 'moralis';

export async function getCode (user) {
    
  //Query Rewards by user Id
  const Rewards = Moralis.Object.extend("Rewards");
  const query = new Moralis.Query(Rewards);
  query.equalTo("userId", user);
  const results = await query.find();
  const token = results[0];
  const code = token.get('code');  
  
  return code;

}

export default getCode;
