import { useMoralis} from 'react-moralis';
import Moralis from 'moralis';
import {Button, Box, Container, Center
} from '@chakra-ui/react'

// Components
import Logo from './components/Logo';
import Auth from './components/Auth';
import GetRewardCode from './components/GetRewardCode';
import Admin from './components/Admin';
import ResetPass from './components/ResetPass';


function App() {
  const { isAuthenticated, user } = useMoralis();
  //const [role, setRole] = useMoralis('User');
  
  let role = '';

  if (isAuthenticated && user != null) {
    //setRole(user.get('Role'));
    role = (user.get('Role'));
    console.log(role);
  }
  
  if(isAuthenticated && role === "Admin") {
    return (
      <Container>
        <Logo />      
        <Admin />
      </Container>
    )
  }

  if(isAuthenticated && role === 'NewUser') {
    return (
      <Container>
        <Logo />      
        <ResetPass />
      </Container>
    )
  }

  if(isAuthenticated && role === 'User') {
    return (
      <Container>
        <Logo />      
        <GetRewardCode></GetRewardCode>
      </Container>
    )
  }

  return (
    <Container  >
      <Logo />
      <Auth></Auth>
    </Container>
    
    
  )
}

export default App;
