import { Box, Image } from '@chakra-ui/react'

const Logo = () => {

    return(
        <Box boxSize='m'  mt={5} >
            <Image src={require('../images/SensitiveCBD_Logo_white.png')} alt='Sensitive_Logo_White' />
        </Box>
    );
    
}

export default Logo;

